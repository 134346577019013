
import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import MainLayout from '../components/MainLayout';

import SubpageHeader from '../components/SubpageHeader';
import TermsOfService from '../components/TermsOfService';

import createMetaData from '../utils/createMetaData';

const PageTermsOfService = () => {
	const { pageTermsOfServiceJson: json } = useStaticQuery(graphql`
		query {
			pageTermsOfServiceJson {
				meta_description
				meta_keywords
				meta_title
				permalink
				title
			}
		}
	`);

	const { site, page, seo } = createMetaData(json);
	return (
		<MainLayout site={site} page={page} seo={seo}>
			<SubpageHeader page={page} />
			<TermsOfService />
		</MainLayout>
	);
};

export default PageTermsOfService;
