
import React from 'react';

const TermsOfService = () => (
	<section className='privacy-policy'>
		<div className='container'>
			<div className='section-title'>
				<h2>Terms of Service</h2>
			</div>

			<p>
				Your use of the service is subject to the terms of this legal agreement between you and us, as amended
				from time to time.
			</p>

			<h3>Agreement</h3>
			<p>
				This Terms and Conditions, along with our Privacy Policy, any mobile license agreement, and other posted
				guidelines within our Website, collectively &quot;Legal Terms&quot;, constitute the entire and only
				agreement between you and johnvincent.io, and supersede all other agreements, representations, warranties
				and understandings with respect to our Website and the subject matter contained herein. We may amend our
				Legal Terms at any time without specific notice to you. The latest copies of our Legal Terms will be
				posted on our Website, and you should review all Legal Terms prior to using our Website. After any
				revisions to our Legal Terms are posted, you agree to be bound to any such changes to them. Therefore, it
				is important for you to periodically review our Legal Terms to make sure you still agree with them. By
				using our Service, you agree to fully comply with and be bound by our Legal Terms. Please review them
				carefully. If you do not accept our Legal Terms, do not access and use our Website. If you have already
				accessed our Website and do not accept our Legal Terms, you should immediately discontinue use of our
				Website.
			</p>

			<h3>Definitions</h3>
			<p>
				The terms &quot;us&quot; or &quot;we&quot; or &quot;our&quot; refers to johnvincent.io. A
				&quot;Member&quot; is an individual that has registered with us to use our Service. Our
				&quot;Service&quot; represents the collective functionality and features as offered through our Website to
				our Members. All text, information, graphics, audio, video, and data offered through our Website are
				collectively known as our &quot;Content&quot;.
			</p>

			<h3>Legal Compliance</h3>
			<p>
				You agree to comply with all applicable domestic and international laws, statutes, ordinances, and
				regulations regarding your use of our Website. johnvincent.io reserves the right to investigate complaints
				or reported violations of our Legal Terms and to take any action we deem appropriate, including but not
				limited to canceling your Member account, reporting any suspected unlawful activity to law enforcement
				officials, regulators, or other third parties and disclosing any information necessary or appropriate to
				such persons or entities relating to your profile, email addresses, usage history, posted materials, IP
				addresses and traffic information, as allowed under our Privacy Policy.
			</p>

			<h3>Intellectual Property</h3>
			<p>
				Our Website may contain our service marks or trademarks as well as those of our affiliates or other
				companies, in the form of words, graphics, and logos. Your use of our Website does not constitute any
				right or license for you to use such service marks/trademarks, without the prior written permission of the
				corresponding service mark/trademark owner. Our Website is also protected under international copyright
				laws. The copying, redistribution, use or publication by you of any portion of our Website is strictly
				prohibited. Your use of our Website does not grant you ownership rights of any kind on our Website.
			</p>

			<h3>Links to Other Websites</h3>
			<p>
				Our Website contains links to third party websites. These links are provided solely as a convenience to
				you. By linking to these websites, we do not create or have an affiliation with or sponsor such third
				party websites. The inclusion of links within our Website does not constitute any endorsement, guarantee,
				warranty, or recommendation of such third party websites. johnvincent.io has no control over the legal
				documents and privacy practices of third party websites; as such, you access any such third party websites
				at your own risk.
			</p>

			<h3>General Terms</h3>
			<p>
				Our Legal Terms shall be treated as though it were executed and performed in NJ, and shall be governed by
				and construed in accordance with the laws of NJ, without regard to conflict of law principles. In
				addition, you agree to submit to the personal jurisdiction and venue of such courts. Any cause of action
				by you with respect to our Website must be instituted within one (1) year after the cause of action arose
				or be forever waived and barred. Should any part of our Legal Terms be held invalid or unenforceable, that
				portion shall be construed consistent with applicable law and the remaining portions shall remain in full
				force and effect. To the extent that any Content in our Website conflicts or is inconsistent with our
				Legal Terms, our Legal Terms shall take precedence. Our failure to enforce any provision of our Legal
				Terms shall not be deemed a waiver of such provision nor of the right to enforce such provision.
			</p>

			<h3>Abuse</h3>
			<p>
				While we strive to provide you as much freedom as possible within our platform, we reserve the rights to
				block access or completely remove user accounts if we find out that the service is used in an abusive
				manner. This includes, but is not limited to the following: Automated registrations for SPAM, SCAM, SEO
				boosting and other similar purposes; subscribing to the same feed from multiple accounts to boost its
				subscriber count; using our platform to send unsolicited emails; constantly trolling and abusing our
				comments function; subscribing to large number of similar feeds from the same provider (e.g. Google) that
				might be considered abusive by the 3rd party.
			</p>

			<h3>Cancellation Policy</h3>
			<p>
				johnvincent.io believes in helping its customers as far as possible and has, therefore, a liberal
				cancellation policy. Either party may terminate this Agreement in their sole discretion, at any time with
				or without cause and regardless of the stated registration period otherwise applicable. We reserve the
				right to suspend or terminate operation of this Service, or any feature of this Service, at any time upon
				notice.
			</p>
		</div>
	</section>
);

export default TermsOfService;
